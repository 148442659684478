import React from "react";

const AccountDeletionInstructions = () => {
  return (
    <div className="flex justify-center mt-10 px-4">
      <div className="w-full max-w-3xl shadow-lg p-6 bg-white">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Account Deletion Guide
        </h1>
        <p className="text-gray-700 text-lg mb-8 text-center">
          Follow these steps carefully to delete your account and ensure that
          all necessary information is backed up. If you face any challenges,
          feel free to reach out to customer support.
        </p>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            1. Access the Settings Menu
          </h2>
          <p className="text-gray-700">
            Open the application on your device and navigate to the main menu,
            usually accessible via an icon in the top corner. Once the menu
            appears, select "Settings." This section houses various options for
            managing your account preferences.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            2. Navigate to Account or Privacy Settings
          </h2>
          <p className="text-gray-700">
            Within the settings menu, look for "Account Settings" or "Privacy
            Settings." These options allow you to manage personal information,
            security, and account preferences. Select the appropriate option to
            proceed.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            3. Locate the Account Deletion Option
          </h2>
          <p className="text-gray-700">
            Scroll through the settings until you find "Delete Account." This
            option may be highlighted or positioned near the bottom of the page.
            Tap on it to initiate the deletion process.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            4. Follow On-Screen Instructions
          </h2>
          <p className="text-gray-700">
            Upon selecting "Delete Account," the app may prompt you for
            additional verification, such as entering your password or answering
            security questions. Carefully read and follow the instructions
            provided to confirm your account deletion.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            5. Backup Important Information
          </h2>
          <p className="text-gray-700">
            Before proceeding with the deletion, ensure that you have backed up
            any essential data, including photos, messages, and files. Many apps
            offer data export options to help with this process. Account
            deletion often results in the permanent loss of your information.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">
            6. Contact Support for Assistance
          </h2>
          <p className="text-gray-700">
            If you encounter any issues during the account deletion process,
            reach out to customer support. Most applications provide support
            options through email, chat, or help centers. Clearly describe your
            issue and provide any relevant details for prompt assistance.
          </p>
        </section>
      </div>
    </div>
  );
};

export default AccountDeletionInstructions;
