import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./layouts/SignIn";
import MainLayout from "./layouts/MainLayout";
import Products from "./pages/Products";
import Orders from "./pages/Orders";
import Customers from "./pages/Customers";
import AddProducts from "./pages/AddProducts";
import {
  BrandsContext,
  CategoryContext,
  CurrentUserContext,
  CustomersContext,
  DeliveryLocationsContext,
  OrdersContext,
  PaymentOptionContext,
  SettingsContext,
  TyresContext,
} from "./utils/context";
import { categoryType, deliveryLocationType, tyreType } from "./types";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "./pages/PageNotFound";
import OrderDetails from "./pages/OrderDetails";
import Brands from "./pages/Brands";
import Categories from "./pages/Categories";
import Settings from "./pages/Settings";
import ProtectedRoutes from "./routeGuard/ProtectedRoutes";
import { User, onAuthStateChanged } from "firebase/auth";
import AnnonymousOnlyRoutes from "./routeGuard/annonymousOnlyRoutes";
import { auth } from "./utils/dbConfig";
import AccountDeletionInstructions from "./pages/AccountDeleteion";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  const [tyres, setTyres] = useState<tyreType | null | any>(null);
  const [customers, setCustomers] = useState(null);
  const [orders, setOrders] = useState(null);
  const [brands, setBrands] = useState(null);
  const [categories, setCategories] = useState<categoryType[] | any>(null);
  const [currentUser, setCurrentUser] = useState<User | any>(null);
  const [terrain, setTerrain] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [deliveryLocations, setDeliveryLocations] = useState<
    deliveryLocationType | any
  >(null);

  const [settings, setSettings] = useState<any>(null);

  useEffect(() => {
    let unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setCurrentUser(currentUser);
    });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  return (
    <>
      <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
        <BrandsContext.Provider value={{ brands, setBrands }}>
          <TyresContext.Provider value={{ tyres, setTyres }}>
            <CustomersContext.Provider value={{ customers, setCustomers }}>
              <OrdersContext.Provider value={{ orders, setOrders }}>
                <CategoryContext.Provider value={{ categories, setCategories }}>
                  <PaymentOptionContext.Provider
                    value={{ paymentOptions, setPaymentOptions }}>
                    <DeliveryLocationsContext.Provider
                      value={{ deliveryLocations, setDeliveryLocations }}>
                      <ToastContainer position="top-center" />
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <ProtectedRoutes>
                              <MainLayout />
                            </ProtectedRoutes>
                          }>
                          <Route index element={<Home />} />
                          <Route path="/products" element={<Products />} />
                          <Route path="/orders" element={<Orders />} />
                          <Route
                            path="/order/details"
                            element={<OrderDetails />}
                          />
                          <Route path="/customers" element={<Customers />} />
                          <Route
                            path="/product/new"
                            element={<AddProducts />}
                          />
                          <Route path="/brands" element={<Brands />} />
                          <Route path="/categories" element={<Categories />} />
                          <Route
                            path="/settings"
                            element={
                              <SettingsContext.Provider
                                value={{
                                  settings,
                                  setSettings,
                                }}>
                                <Settings />
                              </SettingsContext.Provider>
                            }
                          />
                        </Route>

                        <Route
                          path="/signin"
                          element={
                            <AnnonymousOnlyRoutes>
                              <SignIn />
                            </AnnonymousOnlyRoutes>
                          }
                        />
                        <Route
                          path="/account-deletion"
                          element={
                            <AnnonymousOnlyRoutes>
                              <AccountDeletionInstructions />
                            </AnnonymousOnlyRoutes>
                          }
                        />
                        <Route
                          path="/privacy-policy"
                          element={
                            <AnnonymousOnlyRoutes>
                              <PrivacyPolicy />
                            </AnnonymousOnlyRoutes>
                          }
                        />
                        <Route path="*" element={<PageNotFound />} />
                      </Routes>
                    </DeliveryLocationsContext.Provider>
                  </PaymentOptionContext.Provider>
                </CategoryContext.Provider>
              </OrdersContext.Provider>
            </CustomersContext.Provider>
          </TyresContext.Provider>
        </BrandsContext.Provider>
      </CurrentUserContext.Provider>
    </>
  );
}

export default App;
