import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: [Insert Date]</p>

      <section>
        <h2>Introduction</h2>
        <p>
          Welcome to [Your Company Name]'s Privacy Policy. This policy outlines
          how we collect, use, and protect your personal information when you
          use our website or services.
        </p>
      </section>

      <section>
        <h2>Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
          <li>Personal Information: Name, email address, phone number, etc.</li>
          <li>Usage Data: Information about how you use our website.</li>
          <li>
            Cookies: Data stored on your device to enhance your experience.
          </li>
        </ul>
      </section>

      <section>
        <h2>How We Use Your Information</h2>
        <p>We use your information for the following purposes:</p>
        <ul>
          <li>To provide and maintain our services.</li>
          <li>To improve our website and services.</li>
          <li>
            To communicate with you, including sending updates and promotional
            materials.
          </li>
        </ul>
      </section>

      <section>
        <h2>Sharing Your Information</h2>
        <p>
          We do not sell or share your personal information with third parties
          except:
        </p>
        <ul>
          <li>With your consent.</li>
          <li>To comply with legal obligations.</li>
          <li>To protect and defend our rights and property.</li>
        </ul>
      </section>

      <section>
        <h2>Security</h2>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure.
        </p>
      </section>

      <section>
        <h2>Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access the personal information we hold about you.</li>
          <li>Request correction or deletion of your personal information.</li>
          <li>Opt-out of receiving promotional communications.</li>
        </ul>
      </section>

      <section>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page with an updated revision date.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <p>
          Email: [Your Email Address]
          <br />
          Address: [Your Company Address]
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
